import { documentApiClient as apiClient } from './configs/AxiosClient'
import { SisArchitecture, SisClient, SisCohort, SisCourse, SisLearner, SisProrgam } from './entities/sis.entity'

export const SisAPI = {
  getArchitectures: async (): Promise<SisArchitecture> => {
    const response: any = await apiClient.get(`/sis/architectures`)
    return response.data
  },
  getClients: async (): Promise<SisClient[]> => {
    const response: any = await apiClient.get(`/sis/architectures/clients`)
    return response.data
  },
  getClient: async (clientId: string): Promise<SisClient> => {
    const response: any = await apiClient.get(`/sis/architectures/clients/${clientId}`)
    return response.data
  },
  getPrograms: async (clientId: string): Promise<SisProrgam[]> => {
    const response: any = await apiClient.get(`/sis/architectures/programs?clientId=${clientId}`)
    return response.data
  },
  getProgram: async (programId: string): Promise<SisProrgam> => {
    const response: any = await apiClient.get(`/sis/architectures/programs/${programId}`)
    return response.data
  },
  getCohorts: async (): Promise<SisCohort[]> => {
    const response: any = await apiClient.get(`/sis/architectures/cohorts`)
    return response.data
  },
  getCohort: async (cohortId: string): Promise<SisCohort> => {
    const response: any = await apiClient.get(`/sis/architectures/cohorts/${cohortId}`)
    return response.data
  },
  getCourses: async (clientId: string): Promise<SisCourse[]> => {
    const response: any = await apiClient.get(`/sis/architectures/courses?clientId=${clientId}`)
    return response.data
  },
  getCourse: async (courseId: string): Promise<SisCourse> => {
    const response: any = await apiClient.get(`/sis/architectures/courses/${courseId}`)
    return response.data
  },
  getLearners: async (clientId: string): Promise<SisLearner[]> => {
    const response: any = await apiClient.get(`/sis/architectures/learners?clientId=${clientId}`)
    return response.data
  }
}
